import {
    Box,
    CircularProgress,
    Container,
    Divider,
    Stack,
} from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { useGetPipelineInstanceQuery } from "../../api/processApi";
import { useMaskMapDisplay } from "../../hooks/useMask";
import DraggableLayout from "../../../common/components/layout/DraggableLayout";
import {
    DateRangeDisplay,
    DetectionsDisplay,
    GeomDisplay,
} from "./DataDisplay";
import WktLayer from "../../../common/utils/WktLayer";
import { PipelineModel } from "../../types/DataTypes";
import useGeoJsonResponse from "../../hooks/useGeoJsonResponse";
import {
    BreadcrumbsRender,
    ExecutionTitleRender,
    PropertiesRender,
    ResultsMap,
} from "../../views/ResultsViewer";
import { wktToBounds } from "../../../common/utils/tools";
import { InteractiveGeoJSON } from "../../../common/utils/InteractiveGeoJSON";
import CompareSentinel from "../../../common/components/map/tools/CompareSentinel";

const dateFormat = (date: string) => {
    return new Date(date).toISOString().split("T")[0];
};



const ChangeViewer = ({
    pipelineInstanceId,
    model,
}: {
    pipelineInstanceId: string;
    model: PipelineModel;
}) => {
    const { data: pipelineInstance } =
        useGetPipelineInstanceQuery(pipelineInstanceId);

    const { geojson, downloadGeoJson } = useGeoJsonResponse(
        pipelineInstance?.output_data?.changes?.id,
        { skip: !pipelineInstance?.output_data?.changes?.id }
    );
    const [feature, setFeature] = useState({});

    const map = useRef<L.Map>(null);
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true);
    }, [map]);

    const { mask, showMask, setShowMask } = useMaskMapDisplay(
        pipelineInstance?.input_data?.mask,
        map
    );
    const [showDetections, setShowDetections] = useState(true);

    const { init, end } = useMemo(() => {
        const value = pipelineInstance?.input_data?.date_range?.payload?.value;
        if (value) {
            const t1 = value?.init;
            const t2 = value?.end;
            return {
                init: dateFormat(t1),
                end: dateFormat(t2),
            };
        }
        return { init: undefined, end: undefined };
    }, [pipelineInstance]);

    const { t1Query, t2Query } = useMemo(() => {
        const dates = pipelineInstance?.output_data?.dates;

        if (dates) {
            const t1min = dateFormat(dates?.t1_min_date);
            const t1max = dateFormat(dates?.t1_max_date);
            const t2min = dateFormat(dates?.t2_min_date);
            const t2max = dateFormat(dates?.t2_max_date);

            const init = t1min !== t1max ? `${t1min}/${t1max}` : t1min;
            const end = t2min !== t2max ? `${t2min}/${t2max}` : t2min;

            return { t1Query: init, t2Query: end };
        }
        return { t1Query: undefined, t2Query: undefined };
    }, [pipelineInstance]);

    return (
        <DraggableLayout
            topLeft={
                <Box
                    sx={{
                        height: 1,
                        width: 1,
                        display: "flex",
                        flexDirection: "column",
                        overflow: "auto",
                    }}
                >
                    <Stack
                        direction={"column"}
                        sx={{ paddingX: 5, paddingY: 2 }}
                        spacing={3}
                    >
                        <BreadcrumbsRender
                            model={model}
                            instanceId={pipelineInstanceId}
                        />
                    </Stack>
                    <Container>
                        <Stack
                            paddingY={2}
                            divider={<Divider variant="middle" flexItem />}
                            spacing={1}
                        >
                            <ExecutionTitleRender
                                pipelineInstance={pipelineInstance}
                            />
                            {pipelineInstance?.input_data?.date_range ? (
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <DateRangeDisplay init={init} end={end} />
                                </Stack>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <GeomDisplay
                                    showMask={showMask}
                                    setShowMask={setShowMask}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <DetectionsDisplay
                                    show={showDetections}
                                    setShow={setShowDetections}
                                    onClick={() =>
                                        downloadGeoJson({
                                            name: pipelineInstance?.name,
                                            properties: {
                                                init: t1Query,
                                                end: t2Query,
                                            },
                                        })
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Container>
                    <Divider flexItem />
                    <PropertiesRender
                        feature={feature}
                        setFeature={setFeature}
                    />
                </Box>
            }
            downRight={
                <ResultsMap map={map}>
                    {showDetections && geojson && (
                        <InteractiveGeoJSON
                            geojson={geojson}
                            setSelectedFeature={setFeature}
                        />
                    )}
                    {showMask && mask && (
                        <WktLayer
                            wktData={mask}
                            pathOptions={{ color: "red", fillOpacity: 0 }}
                        />
                    )}
                    <CompareSentinel
                        init={t1Query}
                        end={t2Query}
                        bounds={mask ? wktToBounds(mask) : undefined}
                    />
                </ResultsMap>
            }
            onDragEnd={updateMapSize}
            onChangeOrientation={updateMapSize}
        />
    );
};

export default ChangeViewer;
