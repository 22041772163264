import {
    Box,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    Stack,
    Switch,
    Typography,
    useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import CompareSentinel from "../../../common/components/map/tools/CompareSentinel";

const ToolsTab = () => {
    const theme = useTheme();
    const [checked, setChecked] = useState(false);
    const [initRange, setInitRange] = useState<boolean>();
    const [endRange, setEndRange] = useState<boolean>();
    const [initStartDate, setInitStartDate] = useState<Dayjs | null>(null);
    const [initEndDate, setInitEndDate] = useState<Dayjs | null>(null);
    const [endStartDate, setEndStartDate] = useState<Dayjs | null>(null);
    const [endEndDate, setEndEndDate] = useState<Dayjs | null>(null);

    const init = useMemo(() => {
        if (!initStartDate) return undefined;
        if (initRange && !initEndDate) return undefined;

        if (initRange)
            return (
                initStartDate.format("YYYY-MM-DD") +
                "/" +
                initEndDate?.format("YYYY-MM-DD")
            );
        return initStartDate.format("YYYY-MM-DD");
    }, [initStartDate, initEndDate, initRange]);

    const end = useMemo(() => {
        if (!endStartDate) return undefined;
        if (endRange && !endEndDate) return undefined;

        if (endRange)
            return (
                endStartDate.format("YYYY-MM-DD") +
                "/" +
                endEndDate?.format("YYYY-MM-DD")
            );
        return endStartDate.format("YYYY-MM-DD");
    }, [endStartDate, endEndDate, endRange]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                height: 1,
                padding: 2,
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    border: 1,
                    borderColor: theme.palette.divider,
                    borderRadius: 2,
                }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    padding={2}
                    alignItems={"center"}
                >
                    <Typography flexGrow={1}>Comparador</Typography>
                    <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                </Stack>
                <Collapse in={checked}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="es-mx"
                    >
                        <Box
                            sx={{
                                border: 1,
                                borderColor: theme.palette.divider,
                                borderRadius: 2,
                                margin: 2,
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                padding={2}
                                alignItems={"center"}
                            >
                                <Typography flexGrow={1}>Lado A</Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={initRange}
                                                onChange={(e) =>
                                                    setInitRange(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Rango de fechas"
                                    />
                                </FormGroup>
                            </Stack>
                            <Stack direction="row" spacing={2} padding={2}>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    slotProps={{
                                        field: {
                                            clearable: true,
                                            onClear: () =>
                                                setInitStartDate(null),
                                        },
                                    }}
                                    label={initRange ? "Inicio" : "Fecha"}
                                    value={initStartDate}
                                    onChange={(newValue: any) => {
                                        setInitStartDate(newValue);
                                    }}
                                    minDate={dayjs("2018-01-01")}
                                    maxDate={
                                        initEndDate ? initEndDate : dayjs()
                                    }
                                    sx={{ flexGrow: 1 }}
                                />
                                {initRange && (
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            field: {
                                                clearable: true,
                                                onClear: () =>
                                                    setInitEndDate(null),
                                            },
                                        }}
                                        label={"Fin"}
                                        value={initEndDate}
                                        onChange={(newValue: any) => {
                                            setInitEndDate(newValue);
                                        }}
                                        sx={{ flexGrow: 1 }}
                                        minDate={
                                            initStartDate
                                                ? initStartDate
                                                : dayjs("2018-01-01")
                                        }
                                        maxDate={dayjs()}
                                    />
                                )}
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                border: 1,
                                borderColor: theme.palette.divider,
                                borderRadius: 2,
                                margin: 2,
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                padding={2}
                                alignItems={"center"}
                            >
                                <Typography flexGrow={1}>Lado B</Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={endRange}
                                                onChange={(e) =>
                                                    setEndRange(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Rango de fechas"
                                    />
                                </FormGroup>
                            </Stack>
                            <Stack direction="row" spacing={2} padding={2}>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    slotProps={{
                                        field: {
                                            clearable: true,
                                            onClear: () =>
                                                setEndStartDate(null),
                                        },
                                    }}
                                    label={endRange ? "Inicio" : "Fecha"}
                                    value={endStartDate}
                                    onChange={(newValue: any) => {
                                        setEndStartDate(newValue);
                                    }}
                                    sx={{ flexGrow: 1 }}
                                    minDate={dayjs("2018-01-01")}
                                    maxDate={endEndDate ? endEndDate : dayjs()}
                                />
                                {endRange && (
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            field: {
                                                clearable: true,
                                                onClear: () =>
                                                    setEndEndDate(null),
                                            },
                                        }}
                                        label={"Fin"}
                                        value={endEndDate}
                                        onChange={(newValue: any) => {
                                            setEndEndDate(newValue);
                                        }}
                                        sx={{ flexGrow: 1 }}
                                        minDate={
                                            endStartDate
                                                ? endStartDate
                                                : dayjs("2018-01-01")
                                        }
                                        maxDate={dayjs()}
                                    />
                                )}
                            </Stack>
                        </Box>
                    </LocalizationProvider>
                </Collapse>
            </Stack>
            {checked && <CompareSentinel init={init} end={end} />}
        </Box>
    );
};

export default ToolsTab;
