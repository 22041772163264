import { latLngBounds, LatLngBounds, LatLngBoundsExpression } from "leaflet";
import Compare from "./Compare";
import RovisenLayer from "../layers/RovisenLayer";
import SentinelLayer from "../layers/SentinelLayer";

const CompareSentinel = ({
    init,
    end,
    sentinel = true,
    bounds,
}: {
    init: string | undefined;
    end: string | undefined;
    sentinel?: boolean;
    bounds?: LatLngBoundsExpression;
}) => {
    return (
        <Compare
            leftElements={
                <>
                    {init && !sentinel && (
                        <RovisenLayer
                            name="rovisen:sentinel_cogs"
                            layerStyle="raster"
                            params={{ TIME: init }}
                            bounds={bounds}
                        />
                    )}
                    {init && sentinel && (
                        <SentinelLayer
                            initDate={
                                init.includes("/")
                                    ? new Date(init.split("/")[0])
                                    : new Date(init)
                            }
                            endDate={
                                init.includes("/")
                                    ? new Date(init.split("/")[1])
                                    : undefined
                            }
                            bounds={bounds}
                            key={
                                bounds
                                    ? bounds instanceof LatLngBounds
                                        ? bounds.toBBoxString()
                                        : latLngBounds(bounds).toBBoxString()
                                    : undefined
                            }
                        />
                    )}
                </>
            }
            rightElements={
                <>
                    {end && !sentinel && (
                        <RovisenLayer
                            name="rovisen:sentinel_cogs"
                            layerStyle="raster"
                            params={{ TIME: end }}
                            bounds={bounds}
                        />
                    )}
                    {end && sentinel && (
                        <SentinelLayer
                            initDate={
                                end.includes("/")
                                    ? new Date(end.split("/")[0])
                                    : new Date(end)
                            }
                            endDate={
                                end.includes("/")
                                    ? new Date(end.split("/")[1])
                                    : undefined
                            }
                            bounds={bounds}
                            key={
                                bounds
                                    ? bounds instanceof LatLngBounds
                                        ? bounds.toBBoxString()
                                        : latLngBounds(bounds).toBBoxString()
                                    : undefined
                            }
                        />
                    )}
                </>
            }
        />
    );
};

export default CompareSentinel;