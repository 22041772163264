import { Box, Container, Divider, Stack } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { VirtuosoHandle } from "react-virtuoso";
import { ImageOverlay, Polyline } from "react-leaflet";
import {
    useGetPipelineInstanceQuery,
    useGetRasterQuery,
} from "../../api/processApi";
import { useInputMaskCartography } from "../../hooks/useMask";
import DraggableLayout from "../../../common/components/layout/DraggableLayout";
import { DetectionsDisplay, RasterDisplay } from "./DataDisplay";
import { wktToBounds } from "../../../common/utils/tools";
import { PipelineModel } from "../../types/DataTypes";
import { useLines } from "../../hooks/useGeoJsonResponse";
import {
    BreadcrumbsRender,
    ExecutionTitleRender,
    ResultsMap,
} from "../../views/ResultsViewer";
import { GeometryList } from "./GeometryLists";

const StreetsViewer = ({
    pipelineInstanceId,
    model,
}: {
    pipelineInstanceId: string;
    model: PipelineModel;
}) => {
    const { data: pipelineInstance } =
        useGetPipelineInstanceQuery(pipelineInstanceId);
    const { data: raster } = useGetRasterQuery(
        pipelineInstance?.input_data?.raster?.id
    );

    const { geometries, downloadGeoJson } = useLines(
        pipelineInstance?.output_data?.roads?.id
    );

    const map = useRef<L.Map>(null);
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true);
    }, [map]);

    useInputMaskCartography(pipelineInstance?.input_data?.raster, map);
    const [showRaster, setShowRaster] = useState(true);
    const [showDetections, setShowDetections] = useState(true);

    const virtuoso = useRef<VirtuosoHandle>(null);
    const scrollToIndex = useCallback(
        (index: number) => {
            virtuoso.current &&
                virtuoso.current.scrollToIndex({
                    index: index,
                    align: "start",
                    behavior: "smooth",
                });
        },
        [virtuoso]
    );

    return (
        <DraggableLayout
            topLeft={
                <Box
                    sx={{
                        height: 1,
                        width: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Stack
                        direction={"column"}
                        sx={{ paddingX: 5, paddingY: 2 }}
                        spacing={3}
                    >
                        <BreadcrumbsRender
                            model={model}
                            instanceId={pipelineInstanceId}
                        />
                    </Stack>
                    <Container>
                        <Stack
                            paddingY={2}
                            divider={<Divider variant="middle" flexItem />}
                            spacing={1}
                        >
                            <ExecutionTitleRender
                                pipelineInstance={pipelineInstance}
                            />
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <RasterDisplay
                                    name={raster?.name}
                                    showRaster={showRaster}
                                    setShowRaster={setShowRaster}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <DetectionsDisplay
                                    show={showDetections}
                                    setShow={setShowDetections}
                                    onClick={() =>
                                        downloadGeoJson({
                                            name: pipelineInstance?.name,
                                        })
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Container>
                    <Divider flexItem />
                    <GeometryList
                        geometries={geometries}
                        map={map}
                        virtuoso={virtuoso}
                    />
                </Box>
            }
            downRight={
                <ResultsMap map={map}>
                    {showDetections &&
                        geometries &&
                        geometries.map((geometry, index) => (
                            <Polyline
                                positions={geometry.vertexs}
                                key={index}
                                eventHandlers={{
                                    click: () => scrollToIndex(index),
                                }}
                            />
                        ))}
                    {raster && showRaster && raster.preview && (
                        <ImageOverlay
                            url={raster.preview}
                            bounds={wktToBounds(raster.preview_bbox)}
                        />
                    )}
                </ResultsMap>
            }
            onDragEnd={updateMapSize}
            onChangeOrientation={updateMapSize}
        />
    );
};

export default StreetsViewer;
