import AceEditor from "react-ace";
import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { GeoJSON } from "react-leaflet";
import gjv from "geojson-validation";
import L from "leaflet";

import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";

import "../../css/geojsonTable.css";
import { Download, Upload } from "@mui/icons-material";
import styled from "@emotion/styled";

const geojsonMarkerOptions = {
    radius: 8,
    fillColor: "#ff7800",
    color: "#000",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
};

const popupOptions = {
    minWidth: 100,
    maxWidth: 250,
    maxHeight: 300,
    className: "popup-classname",
};

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const GeoJSONTab = () => {
    const [geoJsonString, setGeoJsonString] = useState("");
    const [geoJSON, setGeoJson] = useState<any>();
    const [fileName, setFileName] = useState("");

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                height: 1,
            }}
        >
            <Stack
                direction="row"
                spacing={2}
                sx={{ padding: 1 }}
                justifyContent="space-around"
            >
                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<Upload />}
                >
                    Abrir geoJSON
                    <VisuallyHiddenInput
                        type="file"
                        accept=".geojson"
                        onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                                setFileName(file.name);
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    const content = e.target?.result as string;
                                    setGeoJsonString(content);
                                    try {
                                        const parsedGeoJSON =
                                            JSON.parse(content);
                                        if (
                                            gjv.isGeoJSONObject(parsedGeoJSON)
                                        ) {
                                            setGeoJson(parsedGeoJSON);
                                        }
                                    } catch {
                                        setGeoJson(undefined);
                                    }
                                };
                                reader.readAsText(file);
                            }
                        }}
                    />
                </Button>
                <Button variant="outlined" endIcon={<Download />} onClick={() => {
                    const blob = new Blob([JSON.stringify(geoJSON, null, 2)], {
                        type: "application/json",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = fileName ? `${fileName}_edited.geojson` : "edited_geojson.geojson";
                    a.click();
                    window.URL.revokeObjectURL(url);
                }}>
                    Guardar archivo
                </Button>
            </Stack>
            <AceEditor
                mode="json"
                theme="monokai"
                fontSize="16px"
                value={geoJsonString}
                onChange={(e) => {
                    setGeoJsonString(e);
                    try {
                        const parsedGeoJSON = JSON.parse(e);
                        if (gjv.isGeoJSONObject(parsedGeoJSON)) {
                            setGeoJson(parsedGeoJSON);
                        }
                    } catch {
                        setGeoJson(undefined);
                    }
                }}
                setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                    useWorker: false,
                    wrap: true,
                }}
                width="100%"
                height="100%"
            />
            {geoJSON && (
                <GeoJSON
                    key={geoJsonString.length}
                    data={geoJSON}
                    onEachFeature={(feature, leafletLayer) => {
                        if (feature.properties) {
                            leafletLayer.bindPopup(() => {
                                const div = document.createElement("div");
                                const propertiesTable =
                                    Object.entries(feature.properties).reduce(
                                        (prev, entry) => {
                                            const [key, value] = entry;
                                            return (
                                                prev +
                                                `<tr><td>${key}</td><td>${value}</td></tr>`
                                            );
                                        },
                                        "<table><tr><th>name</th><th>value</th></tr>"
                                    ) + "</table>";

                                div.innerHTML = `<div>Propiedades<div>${propertiesTable}</div></div>`;

                                const button = document.createElement("button");
                                button.innerHTML = "Borrar";

                                button.onclick = function () {
                                    setGeoJson((prev: any) => {
                                        if (prev && feature.id !== undefined) {
                                            const features =
                                                prev.features.filter(
                                                    (f: any) =>
                                                        f.id !== feature.id
                                                );
                                            const newGeoJSON = {
                                                ...prev,
                                                features,
                                            };
                                            setGeoJsonString(
                                                JSON.stringify(
                                                    newGeoJSON,
                                                    null,
                                                    2
                                                )
                                            );
                                            return newGeoJSON;
                                        }
                                        return prev;
                                    });
                                };

                                div.appendChild(button);

                                return div;
                            }, popupOptions);
                        }
                    }}
                    pointToLayer={(point, latlng) =>
                        L.circleMarker(latlng, geojsonMarkerOptions)
                    }
                />
            )}
        </Box>
    );
};

export default GeoJSONTab;
